import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import at1 from '../../Assets/images/j1.webp';
import at2 from '../../Assets/images/ci3.webp';
import at3 from '../../Assets/images/jonit2.webp';
import mt1 from '../../Assets/images/cervical.webp';
import mt2 from '../../Assets/images/ci2.webp';
import mt3 from '../../Assets/images/ci3.webp';
import vt1 from '../../Assets/images/si3.webp';
import vt2 from '../../Assets/images/si1.webp';
import vt3 from '../../Assets/images/si2.webp';
import ct1 from '../../Assets/images/Piles.jpg';
import ct2 from '../../Assets/images/piles.webp';
import ct3 from '../../Assets/images/p3.webp';
import st1 from '../../Assets/images/mi3.webp';
import st2 from '../../Assets/images/mi2.webp';
import st3 from '../../Assets/images/mi1.webp';
import vk1 from '../../Assets/images/ru3.webp';
import vk2 from '../../Assets/images/ru2.webp';
import vk3 from '../../Assets/images/ru1.webp';

const Mainservices = () => {
  return (
    <div>
      <div className='serviceback' id='services'>
        <section id="departments" className="departments section">
          <div className="container section-title text-center" data-aos="fade-up" data-aos-delay="100" >
            <h2 className='text-center aboutheading'>Our Expertise</h2>
            <h6 className='text-center pt-2'>Comprehensive care for pain and recovery</h6>
          </div>
          <div className="container my-lg-5 py-5 px-4 px-lg-0" data-aos="fade-left" data-aos-delay="200">
            <div className="row">
              <div className="col-lg-3 navtabbg">
                <ul className="nav nav-tabs flex-column">
                  <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#departments-tab-1">Joint Pain</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#departments-tab-2">Cervical</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#departments-tab-3">Sciatica Pain</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#departments-tab-4">Piles</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#departments-tab-5">Migraine</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#departments-tab-6">Rheumatic Disorder</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-9 mt-4 mt-lg-0" data-aos="fade-right" data-aos-delay="200">
                <div className="tab-content">

                  {/* Joint Pain */}
                  <div className="tab-pane active" id="departments-tab-1">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Joint Pain</h3>
                        <p className="servicetext">Our joint pain therapy focuses on reducing discomfort and inflammation in your joints, and enhancing your overall joint health. Our treatments help address a variety of conditions such as osteoarthritis, and other degenerative joint diseases. Through a combination of physical therapy, and specialized care, we aim to restore your quality of life and help you return to your normal daily activities without pain.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={at1} alt="Joint Pain Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={at2} alt="Joint Pain Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={at3} alt="Joint Pain Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>

                  {/* Cervical */}
                  <div className="tab-pane" id="departments-tab-2">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Cervical Pain</h3>
                        <p className=" servicetext">Our cervical pain management program offers relief from neck pain, stiffness, and tension that can result from poor posture, injury, or degenerative disc disease. Whether caused by lifestyle habits or spinal conditions, our treatments are designed to improve neck mobility and reduce pain. With a combination of neck exercises, physiotherapy, we help you regain pain-free neck movement and prevent recurring discomfort.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={mt1} alt="Cervical Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={mt2} alt="Cervical Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={mt3} alt="Cervical Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>

                  {/* Sciatica */}
                  <div className="tab-pane" id="departments-tab-3">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Sciatica Pain</h3>
                        <p className="servicetext">Our sciatica treatment plan provides targeted relief from pain caused by irritation of the sciatic nerve. This condition, often leading to discomfort in the lower back, hips, and legs, can severely impact your ability to move freely. Our therapies combine stretching exercises, massage, and other non-invasive treatments to ease pressure on the nerve, reduce inflammation, and restore mobility so that you can lead an active lifestyle again.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={vt1} alt="Sciatica Pain Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={vt2} alt="Sciatica Pain Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={vt3} alt="Sciatica Pain Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>

                  {/* Piles */}
                  <div className="tab-pane" id="departments-tab-4">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Piles Treatment</h3>
                        <p className="servicetext">Our piles treatment focuses on alleviating pain, swelling, and discomfort associated with hemorrhoids. We use a combination of lifestyle changes, dietary adjustments, and soothing therapies to minimize the symptoms of piles. For more severe cases, we offer minimally invasive procedures that ensure effective relief with minimal recovery time, helping you return to normal activities as quickly as possible.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={ct1} alt="Piles Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={ct2} alt="Piles Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={ct3} alt="Piles Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>

                  {/* Migraine */}
                  <div className="tab-pane" id="departments-tab-5">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Migraine Relief</h3>
                        <p className=" servicetext">Our migraine relief therapy helps manage and prevent debilitating headaches through a combination of massage therapy, relaxation techniques. We focus on reducing the frequency and intensity of migraines by identifying and addressing potential triggers, whether they are dietary, environmental, or stress-related. By helping you develop personalized migraine management strategies, we aim to improve your overall well-being.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={st1} alt="Migraine Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={st2} alt="Migraine Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={st3} alt="Migraine Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>

                  {/* Rheumatic Disorder */}
                  <div className="tab-pane" id="departments-tab-6">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Rheumatic Disorder</h3>
                        <p className=" servicetext">Our rheumatic disorder treatments provide relief from the pain and stiffness associated with various autoimmune and inflammatory conditions. We offer a range of therapies designed to manage symptoms, reduce inflammation, and improve your joint function. With our holistic approach to care, we aim to improve your overall quality of life, ensuring you can manage your condition more effectively while reducing the risk of long-term joint damage.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={vk1} alt="Rheumatic Disorder Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={vk2} alt="Rheumatic Disorder Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={vk3} alt="Rheumatic Disorder Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Mainservices;
