import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store  from './store/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <Provider store={store}>
  //     <BrowserRouter>
  //       <App />
  //       <ToastContainer />
  //     </BrowserRouter>
  //   </Provider>
  // </React.StrictMode>
  <React.StrictMode>
  <Provider store={store}>
      <App />
      <ToastContainer />
  </Provider>
</React.StrictMode>
);
reportWebVitals();
