import React, { useEffect, useState } from 'react'
import Teambanner from '../../components/Teambanner'
import milestone from '../../Assets/images/drpathakji.webp'
import axios from 'axios'

const Ourteam = () => {
    const [ourTeam, setOurTeam] = useState([]);
    const getImages = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/ourteam').then((data) => {
            if (data) {
                setOurTeam(data.data)
            }
        })
    }

    useEffect(() => {
        getImages()
    }, [])

    return (
        <div>
            <Teambanner />
            <div className='container my-lg-5 pt-5 pb-lg-5 pb-9'>
                <div className="container section-title text-center" data-aos="fade-up">
                    <h2 className="text-center aboutheading">Our Milestone</h2>
                    <h6 className="text-center pt-2">
                        Meet the Visionaries and Leaders Shaping Our Success
                    </h6>
                </div>
                {/* founder */}
                <div className='row py-lg-5 px-lg-0 px-3'>
                    <div className='col-md-8 order-2 order-md-1' data-aos="fade-left" data-aos-delay="100">
                        <h2 className='serviceheading fs-3'>Founder and President</h2>
                        <h6 className="pt-2 fs-4 aboutheading">
                            Dr. Raghav Pathak
                        </h6>
                        <p className='text-justify servicetext py-3'>
                            Dr. Raghav Pathak is the visionary behind Dr. Pathak's Holistic Cure, dedicated to providing exceptional healthcare services. With over <strong>15 years</strong>  of experience in the medical field, Dr. Pathak has been a pioneer in introducing advanced medical treatments and technologies in the region. Committed to compassionate care and innovative practices, Dr. Pathak has earned a reputation for excellence in patient care and hospital management. The foundation of Dr. Pathak's Holistic Cure was laid with a mission to make quality healthcare accessible to all, and under Dr. Pathak's leadership, the organization has grown to serve a wide community.
                        </p>
                        <p className='text-justify servicetext'>
                            Under Dr. Pathak's guidance, Dr. Pathak's Holistic Cure has become a beacon of hope for countless patients. The organization’s ethos of patient-centric care, coupled with cutting-edge technology and a dedicated team of healthcare professionals, reflects Dr. Pathak’s commitment to improving healthcare outcomes and enhancing the quality of life for all patients.
                        </p>

                        <div className='text-justify py-3'>
                            <h6 className="pt-2 fs-4 aboutheading">Positions Held</h6>
                            <ul>
                                <li>CMD, Dr. Pathak's Holistic Cure</li>
                                <li>Founder and President, Shree Yog and Health Organisation, Khajuraho</li>
                                <li>State President, Integrated Ayush Council, Madhya Pradesh</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-4 text-center order-1 order-md-2 py-4' data-aos="fade-right" data-aos-delay="200">
                        <img className="img-fluid pl-lg-5" src={milestone} alt='Dr Raghav Pathak' width={440} />
                    </div>
                </div>
                <div className='row py-lg-5 d-none'>
                    <div className='' data-aos="fade-up">
                    <h1 className=" fs-2 serviceheading text-center" >Awards</h1>
                    <h6 className="text-center pt-2 pb-5 fs-4 aboutheading">
                    Explore the Awards We’ve Achieved
                    </h6>
                    </div>
                    <div className='col-md-3 mb-4' data-aos="fade-up" data-aos-delay="100">
                        <div className='card h-100 d-flex flex-column'>
                            <img src='https://via.placeholder.com/150' className='card-img-top' alt='AwardImage 1' />
                            <div className='card-body d-flex flex-column'>
                                <h5 className='card-title mt-auto text-center'>Special Guest of Honor Award</h5>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-3 mb-4' data-aos="fade-up" data-aos-delay="200">
                        <div className='card h-100 d-flex flex-column'>
                            <img src='https://via.placeholder.com/150' className='card-img-top' alt='Award Image 2' />
                            <div className='card-body d-flex flex-column'>
                                <h5 className='card-title mt-auto text-center'>Special Guest of Honor</h5>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-3 mb-4' data-aos="fade-up" data-aos-delay="300">
                        <div className='card h-100 d-flex flex-column'>
                            <img src='https://via.placeholder.com/150' className='card-img-top' alt='Award Image 3' />
                            <div className='card-body d-flex flex-column'>
                                <h5 className='card-title mt-auto text-center'>Swabhiman Award for Holistic Approach</h5>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-3 mb-4' data-aos="fade-up" data-aos-delay="400">
                        <div className='card h-100 d-flex flex-column'>
                            <img src='https://via.placeholder.com/150' className='card-img-top' alt='AwardImage4' />
                            <div className='card-body d-flex flex-column'>
                                <h5 className='card-title mt-auto text-center'>Special Guest of Honor by Vice Chancellor</h5>
                            </div>
                        </div>
                    </div>
                </div>
                {/* manager */}
                {/* <div className='row py-5'>
                    <div className='col-md-4'>
                        <img className="img-fluid" src={milestone} alt='Dr Raghav Pathak' width={450} />
                    </div>
                    <div className='col-md-1 spacecol'></div>
                    <div className='col-md-7'>
                        <h2 className='serviceheading fs-3'>Founder</h2>
                        <h6 className="pt-2 fs-4 aboutheading">
                            Dr Raghav Pathak
                        </h6>
                        <p className='text-justify servicetext pt-3'>
                            Dr. [Founder Name] is the visionary behind [Hospital Name], dedicated to providing exceptional healthcare services. With over [number of years] years of experience in the medical field, Dr. [Founder Name] has been a pioneer in introducing advanced medical treatments and technologies in the region. Committed to compassionate care and innovative practices, Dr. [Founder Name] has earned a reputation for excellence in patient care and hospital management. The foundation of [Hospital Name] was laid with a mission to make quality healthcare accessible to all, and under Dr. [Founder Name]'s leadership.
                        </p>
                        <p className='text-justify servicetext '>
                            Under Dr. [Founder Name]'s guidance, [Hospital Name] has become a beacon of hope for countless patients. The hospital's ethos of patient-centric care.
                        </p>
                    </div>

                </div> */}
            </div>
            {/* rest team */}
            <div className='serviceback d-none '>
                <div className='container py-lg-5'>
                    <div className="intro" data-aos="fade-up">
                        <h2 className="text-center aboutheading">Our Team</h2>
                        <h6 className='text-center pt-2 pb-5'> Introducing Our Skilled and Dedicated Staff</h6>
                    </div>
                    <div className="row people py-lg-5 leftspace">
                        {
                            ourTeam.length ? ourTeam.map((item) => {
                                return <div className="col-md-6 col-lg-4 item my-lg-4">
                                    <div className="box">
                                    <img
                                            src={`${process.env.REACT_APP_BACKEND}team/${item.image}`}
                                           alt={item.filename}
                                           width={50}
                                           className="rounded-circle"
                                        />
                                        <h4 className="name">{item.name}</h4>
                                        <p className="title">{item.role}</p>
                                        <p className="description">
                                        {item.description}
                                        </p>
                                    </div>
                                </div>
                            }) : <p className='bg-danger'>No Team Member Found !</p>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Ourteam
