import React from 'react'
import { FaArrowAltCircleUp } from "react-icons/fa";
const Stickyicon = () => {
    return (
        <div className="">
            <div className="sticky-contact-icon">
                <a
                    href='#'>
                    <FaArrowAltCircleUp className='top-icon' />
                
                </a>
            </div>
        </div>
    )
}

export default Stickyicon