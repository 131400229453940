import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrderDetails } from '../../slice/orderSlice';
const MyOrder = () => {

  const data = useSelector(cart => cart.order);
  const user = useSelector(store => store.user)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [orders, setOrders] = useState([
    {
      id: 1,
      orderDate: '2024-08-28',

      items: [
        { product: 'Product 1', price: 29.99, quantity: 2, image: 'https://via.placeholder.com/100' }

      ],
    },
    {
      id: 2,
      orderDate: '2024-08-27',

      items: [
        { product: 'Product 3', price: 19.99, quantity: 1, image: 'https://via.placeholder.com/100' }

      ],
    },
  ]);

  const calculateTotal = (items) => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  useEffect(() => {
    dispatch(getAllOrderDetails(user?.response?.id))
  }, [dispatch])

  const handleClick = (id) => {
    navigate(`${id}`)
  }

  return (
    <div className="container my-5">
      <h2 className="pb-5 text-center aboutheading">Order History</h2>
      <div className='row justify-content-center align-items-center pl-lg-2 pl-2'>
        <div className='col-md-12'>
          <div class="table-responsive">
            <table class="table mb-0">
              <thead class="small text-uppercase bg-body text-muted">
                <tr>
                  <th>Transaction ID</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>

                { data && data?.response?.length && data?.response?.map((order) => (
                  <tr class="align-middle" onClick={()=> handleClick(order.id)}>
                    <td>
                      #{order.order_id}
                    </td>
                    <td>{order.createdAt}</td>
                    <td>
                      <div class="d-flex align-items-center">
                       
                        <span>  {order.total_amount}</span>
                      </div>
                    </td>
                    <td>
                      <span class="badge fs-6 fw-normal bg-tint-success text-success">{ order?.status}</span>
                    </td>
                  </tr>

                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="text-center mt-5 pb-5">
        <Link to="/" className="checkout text-decoration-none">
          Continue Shopping
        </Link>
      </div>
    </div>
  );
};

export default MyOrder;