import React, { useState, useEffect } from 'react';
import axios from 'axios';
const MediaNews = () => {
  const [images, setImages] = useState([]);
  const getImages = () => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/media').then((data) => {
      if (data) {
        setImages(data.data)
      }
    })
  }
  useEffect(() => {
    getImages()
  }, [])

  const [visibleImages, setVisibleImages] = useState(9);
  const handleViewMore = () => {
    setVisibleImages(prev => Math.min(prev + 9, images.length));
  };
  
  return (
    <div>
      <div className='container my-5'>
        <div className='' data-aos="fade-up">
        <h2 className='text-center aboutheading'>Media & News</h2>
        <h6 className='text-center pt-2 pb-5'>
          Stay Updated with the Latest News & Media 
        </h6>
        </div>
        <div className="row pt-lg-5 leftspace">
        {images.slice(0, visibleImages).map((image, index) => (
            <div key={index} className="col-lg-4 col-md-12 gallery-col">
              <div className="gallery-item">
                <img
                  src={`${process.env.REACT_APP_BACKEND}media/${image.image}`}
                  className="w-100 gallery-img "
                  loading='lazy'
                  alt={`Media item ${index + 1}`}
                />
              </div>
            </div>
          ))}
        </div>
        {visibleImages < images.length && (
          <div className="text-center mt-4">
            <button onClick={handleViewMore} className="checkout">
              View More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaNews;
