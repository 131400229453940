import React from 'react'
import pathakjiimg from '../../Assets/images/fbbanner.webp'
import { Link } from 'react-router-dom'

const Drpathakji = () => {
  
    
    return (
        <div>
            <div className='pt-5 pb-5'>
            <div className='container pt-5 my-5'>
                <div className='row pb-5'>
                    <div className='col-6'>
                        <h2 className='aboutheading'>Dr Pathak's</h2>
                        <h3 className='aboutheading '>Holistic Cure Khajuraho/Bhopal MP</h3>
           
                        <p className='text-justify pathakjiintro py-4'>
                            Dr. Raghav Pathak is an experienced homeopathy physician, recognized both nationally and internationally for his effective treatments using homeopathy and Ayurveda. He is committed to comprehensive disease diagnosis based on Ayurveda, Yoga, and Naturopathy. Through various essential practices, he treats conditions like arthritis, sciatica, cervical spondylosis, slipped disc, migraine, bone pain, back pain, knee pain, shoulder pain, body aches, and muscle pain. With the message of 'Vasudhaiva Kutumbakam' (the world is one family), Dr. Raghav Pathak is dedicated to fulfilling humanitarian goals by continuously working to improve and expand yoga and health. All doctors on our team work on our advanced medical methods to treat patients effectively.
                        </p>
                   
                        <div className="mt-4">
                    <Link to=''>
                      <button className="text-decoration-none pathakjibtn font-weight-bold">
                        More Info
                      </button>
                    </Link>
                  </div>
                    </div>
                    <div className='col-6 '>
                     <img src={pathakjiimg} alt='pathakjiimg' className='pathakji' />
                    </div>                              
                </div>
            </div>
        </div>
        </div>
    )


}

export default Drpathakji
