import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  response: null,
  loading: false,
  error: null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    login: (state, payload) => {
      state.loading = true;
    },
    failed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    success(state, action) {
      state.loading = false;
      state.response = action.payload;
      state.error = null;
    },
    getorderId(){

    },
    postOrder(){

    },
  },
})

// Action creators are generated for each case reducer function
export const { login, failed, success, getorderId, postOrder } = paymentSlice.actions

export default paymentSlice.reducer