import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
const Medicinehome = (props) => {

  const [products, setProducts] = useState([])

  useEffect(() => {
    setProducts(props.product_data)
  }, [props.product_data])


  return (
    <div className='container my-5 py-5'>
      <div className="container section-title text-center" data-aos="fade-up">
        <h2 className='text-center aboutheading'>Medicines</h2>
        <h6 className='text-center pt-2 pb-5'>Quality Medicines for Optimal Health and Wellbeing</h6>

      </div>
      <div className="row pt-lg-5 leftspace">
        {products.map(product => (
          <div key={product.id} className="col-md-3 mb-5" >
             <Link to={`/medicinesdetails/${product.id}`}  className='text-decoration-none productlink'>
            <div className="product text-center">
              <div className="productimgdiv">
                <img className="product-image" loading='lazy' src={ process.env.REACT_APP_BACKEND_URL_REL + product.image} alt={product.name} />          
              </div>
              <div className="card-body pcardbody py-4">
                <h4 className="fs-5">{product.name}</h4>
               
                <h6 className="fw-bolder fs-4">Rs {product?.sprice}</h6>
                <Link to={`/medicinesdetails/${product.id}`}>
                      <button className=" text-decoration-none productbtn font-weight-bold">More Info</button>
                    </Link>
              </div>
            </div>
            </Link>
          </div>
      

        ))}
      </div>

      <div className='text-center mt-lg-5'>
        <Link to='/medicines'
          className='text-center viewmorebtn text-decoration-none'>View More
        </Link>
      </div>
    </div>
  );
};

export default Medicinehome;
