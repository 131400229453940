import React, { useState } from 'react';
import border from '../../Assets/images/border2.png';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { addContectUs, failed } from '../../slice/contactusSlice';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../common/loader/loader.gif';
import CommonAlert from '../../common/CommonAlert';

const Contactus = () => {
  const dispatch = useDispatch();
  const contactData = useSelector((store) => store.contact);
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    address:'',
    subject: '',
    message: '',
   
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = 'Name is required';
    }
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(formData.mobile)) {
      errors.mobile = 'Phone number must be 10 digits';
    }
    if (!formData.address) {
      errors.address = 'Address is required';
    }
    if (!formData.subject) {
      errors.subject = 'Subject is required';
    }
    if (!formData.message) {
      errors.message = 'Message is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(addContectUs(formData));
    }
  };

  const onConfirm = () => {
    dispatch(failed());
  };

  return (
    <div>
      {contactData?.response?.length && (
        <CommonAlert
          title="Thanks For Contact Us"
          text="We will contact you soon"
          icon="success"
          confirmButtonText="Ok"
          onConfirm={onConfirm}
        />
      )}
      <div className="contactbg" id="contactus">
        <section id="contact" className="contact section text-white">
          <div className="container section-title text-center" data-aos="fade-up">
            <h2 className="text-center appointmentheading">Contact Us</h2>
            <h6 className="text-center pt-2 text-white">
              Get in Touch with Us for Any Queries, Feedback, or Support. We're Here to Help You!
            </h6>
            <img src={border} className="img-fluid pb-5" alt="border" width={200} />
          </div>

          <div className="container" >
            <div className="row gy-5 py-lg-5 leftspace">
              <div className="col-lg-4">
                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                  <FaMapMarkerAlt className="flex-shrink-0 mr-2" size={25} />
                  <div>
                    <h3>Location</h3>
                    <p>
                      Dr. Pathak's Holistic Cure Bharat Bhavan Foujdar Mohalla Rajnagar District Chhatarpur MP,
                      Rajnagar, India, Madhya Pradesh
                    </p>
                  </div>
                </div>

                <div className="info-item d-flex" >
                  <FaPhoneAlt className="flex-shrink-0 mr-2" size={25} />
                  <div>
                    <h3>Call Us</h3>
                    <p>077480 18010</p>
                  </div>
                </div>

                <div className="info-item d-flex" >
                  <FaEnvelope className="flex-shrink-0 mr-2" size={25} />
                  <div>
                    <h3>Email Us</h3>
                    <p>yogidrraghav@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 spacecol"></div>
              <div className="col-lg-7">
                <form onSubmit={handleSubmit} className="php-email-form" >
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        onChange={handleChange}
                        placeholder="Full Name"
                        value={formData.name}
                        required
                      />
                      {errors.name && <small className="text-danger">{errors.name}</small>}
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        name="mobile"
                        onChange={handleChange}
                        placeholder="Mobile Number"
                        value={formData.mobile}
                        required
                      />
                      {errors.mobile && <small className="text-danger">{errors.mobile}</small>}
                    </div>
                    <div className="col-md-12 contactusfrom">
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        onChange={handleChange}
                        placeholder="Full Address"
                        value={formData.address}
                      />
                    </div>
                   
                    <div className="col-md-12 contactusfrom">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        onChange={handleChange}
                        placeholder="Subject"
                        value={formData.subject}
                        required
                      />
                      {errors.subject && <small className="text-danger">{errors.subject}</small>}
                    </div>

                    <div className="col-md-12 contactusfrom">
                      <textarea
                        className="form-control"
                        name="message"
                        onChange={handleChange}
                        rows="4"
                        placeholder="Message"
                        value={formData.message}
                        required
                      ></textarea>
                      {errors.message && <small className="text-danger">{errors.message}</small>}
                    </div>

                    <div className="col-md-12 text-center">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">Your message has been sent. Thank you!</div>
                      <button type="submit" className="text-start">
                        {contactData?.loading ? <img src={loader} alt="loader" width={50} /> : 'Send Message'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.3641639772886!2d79.9068490748274!3d24.88555704425385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3982e974f564712d%3A0xbf75edeada4bf3ff!2sDr.%20Pathak&#39;s%20Holistic%20cure!5e0!3m2!1sen!2sin!4v1724610149098!5m2!1sen!2sin"
          className="border-0 w-100"
          height={300}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map showing Dr. Pathak's Holistic Cure location"
        ></iframe>

      </div>
    </div>
  );
};

export default Contactus;
