import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signin } from '../../slice/userSlice';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Login = () => {
  const [formData, setFormData] = useState({ username: '', email: '', password: '', mobile: '' });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userdata = useSelector(store => store.user);
  const MySwal = withReactContent(Swal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.email) tempErrors.email = "Email is required";
    if (!formData.password) tempErrors.password = "Password is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  useEffect(() => {
    console.log(userdata?.response)
    if (userdata?.response !== null && Object.keys(userdata?.response).length) {
       MySwal.fire({
        title: 'Login Successful',
        text: 'You have been logged in successfully!',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Continue',
      });
      navigate('/myprofile');
    } else if (userdata?.message === 'Logout Successfully') {
       MySwal.fire({
        title: 'success',
        text: `${userdata?.message}` ,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
      });
    }
    else if (userdata?.message) {
      MySwal.fire({
       title: 'Error',
       text: `${'Error'}` ,
       icon: 'error',
       confirmButtonColor: '#3085d6',
       confirmButtonText: 'OK',
     });
   }

  }, [dispatch, userdata, navigate])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        // Perform login action
        await dispatch(signin(formData));

      } catch (error) {
        // Show error alert
        await MySwal.fire({
          title: 'Login Failed',
          text: 'There was an issue with your login. Please try again.',
          icon: 'error',
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK',
        });
        console.error("Login failed: ", error);
      }
    }
  };

  return (
    <>
      <div className="maincontainer d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row leftspace justify-content-center align-items-center">
            <div className="col-lg-8 col-md-8">
              <div className="bg-light p-5 rounded">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control p-2 registerinputs"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    {errors.email && <small className="text-danger">{errors.email}</small>}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="password"
                      className="form-control p-2 registerinputs"
                      placeholder="Enter password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    {errors.password && <small className="text-danger">{errors.password}</small>}
                  </div>
                  <div className="form-group text-center">
                    <button className="px-4 py-1 loginbtn border btn-block mt-4 rounded" type="submit">Login</button>
                    <p className='pt-3'>Don't have an account? <Link className='rbtn' to="/register">Register</Link></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
