import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'; // Make sure this import is uncommented or added.
import { postRequest } from '../../apis/ApiInstence';


const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(store => store.user);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    whatsapp: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    password: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};

    if (!formData.name) tempErrors.name = "Username is required";
    if (!formData.email) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email is not valid";
    }

    if (!formData.mobile) {
      tempErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      tempErrors.mobile = "Mobile number must be 10 digits";
    }

    if (!formData.city) tempErrors.city = "City is required";
    if (!formData.state) tempErrors.state = "State is required";

    if (!formData.zip) {
      tempErrors.zip = "Zip/Postal Code is required";
    } else if (!/^\d{5,6}$/.test(formData.zip)) {
      tempErrors.zip = "Zip/Postal Code is not valid";
    }

    if (!formData.password) tempErrors.password = "Password is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await postRequest('customer', formData);
        if (response?.status === 200) {
          // Show a SweetAlert success message
          await Swal.fire({
            title: "Registration Successful",
            text: "You have registered successfully!",
            icon: "success",
            confirmButtonText: "OK"
          });
          
          // Navigate to the login page after the user clicks "OK"
          navigate('/otp', { state: formData.email });
        }
      } catch (error) {
        // Handle error with a SweetAlert error message if needed
        Swal.fire({
          title: "Registration Failed",
          text: "An error occurred. Please try again.",
          icon: "error",
          confirmButtonText: "OK"
        });
      }
    }
  };
  

  return (
    <>
      <div className="maincontainer d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row leftspace justify-content-center align-items-center" id='registertab'>
            <div className="col-lg-8 col-md-8">
              <div className="bg-light p-5 rounded">
                <form onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="Full Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        {errors.name && <small className="text-danger">{errors.name}</small>}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group mb-3">
                        <input
                          type="email"
                          className="form-control p-2 registerinputs"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        {errors.email && <small className="text-danger">{errors.email}</small>}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className="form-group mb-3">
                        <input
                          type="tel"
                          className="form-control p-2 registerinputs"
                          placeholder="Mobile Number"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                          required
                        />
                        {errors.mobile && <small className="text-danger">{errors.mobile}</small>}
                      </div>
                    </div>
                    {/* <div className='col-md-6'>
                      <div className="form-group mb-3">
                        <input
                          type="tel"
                          className="form-control registerinputs p-2"
                          placeholder="WhatsApp Number"
                          name="whatsapp"
                          value={formData.whatsapp}
                          onChange={handleChange}
                          required
                        />
                        {errors.whatsapp && <small className="text-danger">{errors.whatsapp}</small>}
                      </div>
                    </div> */}
                  </div>
                  <div className='row'>
                    <div className='col-md-12 colinmobile'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="House No/Landmark"
                          name="address"
                          value={formData.houseNo}
                          onChange={handleChange}
                          
                        />
                        {errors.houseNo && <small className="text-danger">{errors.houseNo}</small>}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="City"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          required
                        />
                        {errors.city && <small className="text-danger">{errors.city}</small>}
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="State"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          required
                        />
                        {errors.state && <small className="text-danger">{errors.state}</small>}
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="Zip / Postal Code"
                          name="zip"
                          value={formData.zip}
                          onChange={handleChange}
                          required
                        />
                        {errors.zip && <small className="text-danger">{errors.zip}</small>}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 colinmobile'>
                      <div className="form-group mb-3">
                        <input
                          type="password"
                          className="form-control p-2 registerinputs"
                          placeholder="Password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          required
                        />
                        {errors.password && <small className="text-danger">{errors.password}</small>}
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button className="px-4 py-1 loginbtn border btn-block mt-4 rounded" type="submit" disabled={data?.loading}>
                      {data?.loading ? 'Registering...' : 'Register'}
                    </button>
                    <p className='pt-3'>Already have an account? <Link className='rbtn' to="/login">Login</Link></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
