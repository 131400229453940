import React, { useState } from 'react';
import RenderRazorpay from '../../components/payment/RenderRazorpay';
import { FaLock } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

function Payment() {
    const [formData, setFormData] = useState({
        paymentMethod: '',
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card w-50">
                <div className="card-header text-center">
                    <span><FaLock /></span> Secure Payment
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <label>Payment Method:</label>
                        <div className="form-check">
                            <input
                                type="radio"
                                className="form-check-input"
                                id="onlinePayment"
                                name="paymentMethod"
                                value="online"
                                checked={formData.paymentMethod === "online"}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="onlinePayment">Online Payment</label>
                        </div>
                        <div className="form-check">
                            <input
                                type="radio"
                                className="form-check-input"
                                id="cashOnDelivery"
                                name="paymentMethod"
                                value="cashOnDelivery"
                                checked={formData.paymentMethod === "cashOnDelivery"}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="cashOnDelivery">Cash on Delivery</label>
                        </div>
                    </div>

                    {formData.paymentMethod === "online" && (
                        <RenderRazorpay
                            orderId={'order_OooqXLNodKcBeM'}
                            keyId={'rzp_test_7BKEnaLq7HKuMD'}
                            keySecret={'6A0pWyz9dTCfAxQCotQbMvhw'}
                            currency={'INR'}
                            amount={100}
                        />
                    )}

                    {formData.paymentMethod === "cashOnDelivery" && (
                        <div className="form-group">
                            <p>You have selected Cash on Delivery. You will pay in cash when your order is delivered.</p>
                        </div>
                    )}

                    <div className="form-group">
                        <button type="submit" className="py-1 loginbtn btn-block">Place Order</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;
