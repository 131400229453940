import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter, FaWhatsapp } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io5";
const Footer = () => {
  return (
    <div>
      <footer id="footer" className="footer light-background">
        <div className="container footer-top ">
          <div className="row gy-4 leftspace">
            <div className="col-lg-5 col-md-6 footer-about">
              <h4>Dr Pathak's Holistic Cure</h4>
              <div className="footer-contact">
                <strong>Address:</strong> <Link to='https://maps.app.goo.gl/5P19RsUVGskXwXQG6' target='_blank' className='text-decoration-none'><p> Dr. Pathak's Holistic Cure Bharat Bhavan Foujdar Mohalla Rajnagar District Chhatarpur MP, Rajnagar, India, Madhya Pradesh</p>  </Link>
                <p className="mt-3">
                  <strong>Phone:</strong>
                  <Link to="tel:+8516064764" className='text-dark fs-6 text-decoration-none'>
                    <span className='text-dark fs-6 pl-2'>8516064764</span>
                  </Link>
                  <Link to="tel:+7566064764" className='text-dark fs-6 text-decoration-none'>
                    <span className='text-dark fs-6 pl-2'>7566064764</span>
                  </Link>
                </p>

                <p>
                  <strong>Email: </strong>
                  <Link to="mailto:yogidrraghav@gmail.com" className='text-dark fs-6  pl-2 text-decoration-none'>
                     yogidrraghav@gmail.com
                  </Link>
                </p>

              </div>
              <div className="social-links d-flex mt-4"> 
                <Link to="https://x.com/PathakCure20979?t=_pT2jUk5bY5xBQN28YNLWA&s=09" target="_blank" className="twitter footericons text-decoration-none "> <FaSquareXTwitter /></Link>
                <Link to="https://www.facebook.com/Drpathakscure?mibextid=kFxxJD" target="_blank" className="facebook footericons"> <FaFacebook /></Link>
                <Link to="https://www.instagram.com/drpathaksholisticcure?igsh=ZXoxcTNpcXk2emhv" target="_blank" className="instagram footericons "> <FaInstagram /></Link>
                <Link to="https://youtube.com/@drpathaksholisticcure?si=QVlXl9WnK0GsvKvg" target="_blank" className="whatsapp  footericons"> <IoLogoYoutube /></Link>
                <Link to="https://wa.me/917748018010" target="_blank" className="whatsapp  footericons"> <FaWhatsapp /></Link>
              </div>
            </div>
            <div className='col-lg-1'></div>
            <div className="col-lg-3 col-md-3 footer-links">
              <h4>Quick Links</h4>
              <ul>
                <li><Link to="/" className='text-decoration-none text-dark fs-6'>Home</Link></li>
                <li><Link to="/services" className='text-decoration-none text-dark fs-6'>Services</Link></li>
                <li><Link to="/medicines" className='text-decoration-none text-dark fs-6'>Medicines</Link></li>
                <li><Link to="/aboutus" className='text-decoration-none text-dark fs-6'>About Us</Link></li>
                {/* <li><Link to="/ourteam" className='text-decoration-none text-dark fs-6'>Our Team</Link></li> */}
                <li><Link to="/contact" className='text-decoration-none text-dark fs-6'>Contact Us</Link></li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3 footer-links pl-lg-5 pl-md-5 ">
              <h4>Our Services</h4>
              <ul>

                <li><Link to="/services#" className='text-decoration-none text-dark fs-6'>Abhyangam Therapy</Link></li>
                <li><Link to="/services" className='text-decoration-none text-dark fs-6'>Marma Therapy</Link></li>
                <li><Link to="/services" className='text-decoration-none text-dark fs-6'>Vibrator Marma Therapy</Link></li>
                <li><Link to="/services" className='text-decoration-none text-dark fs-6'>Shirodhara Therapy</Link></li>
                <li><Link to="/services" className='text-decoration-none text-dark fs-6'>Vasti Karma Therapy</Link></li>
              </ul>
            </div>
            {/* <div className='col-lg-1 spacecol'></div> */}
            {/* <div className="col-lg-2 col-md-3 footer-links">
              <h4>Legal</h4>
              <ul>
                <li><Link to="/" className='text-decoration-none text-dark fs-6'>Privacy Policy</Link></li>
                <li><Link to="/" className='text-decoration-none text-dark fs-6'>Terms of Service</Link></li>
               
              </ul>
            </div> */}

          </div>
        </div>
        <div className='copyright'>
          <div className="container copyright text-center">
            <p>© <span>Copyright 2024</span> <strong className="px-1 sitename">DPHC</strong> <span>All Rights Reserved</span></p>
            <div className="credits">
              Designed & Developed by <Link to="https://yashsoftsolution.com/" target="_blank" className='text-decoration-none text-light fs-6'>Yashsoft Solution</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;