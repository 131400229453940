import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { failed, otpverification } from '../../slice/userSlice';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const Otp = () => {
  const data = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location || {};

  const [formData, setFormData] = useState({
    otp: '',
    email: state,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.otp) tempErrors.otp = 'OTP is required';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      dispatch(otpverification(formData));
      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (submitted && data?.response?.status === 'verified') {
      Swal.fire({
        title: 'You are registered successfully',
        text: 'Please Login',
        icon: 'success',
        confirmButtonColor: 'green',
        confirmButtonText: 'Login',
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(failed())
          navigate('/login');
        }
      });
      setLoading(false);
    } else if (submitted && data?.response?.data?.status === 'error') {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong. Please try again.',
        icon: 'error',
      });
      setLoading(false);
    } else if (submitted && data?.message) {
      Swal.fire({
        title: 'Error',
        text: `Something went wrong. ${data.message}`,
        icon: 'error',
      });
      setLoading(false);
    }
  }, [data, navigate, submitted, dispatch]);

  return (
    <div className="maincontainer d-flex align-items-center justify-content-center">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8 col-md-8">
            <div className="bg-light p-5 rounded">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control p-2 registerinputs"
                        placeholder="Enter 6 Digit OTP"
                        name="otp"
                        value={formData.otp}
                        onChange={handleChange}
                        required
                      />
                      {errors.otp && <small className="text-danger">{errors.otp}</small>}
                    </div>
                  </div>
                </div>
                <div className="form-group text-center">
                  <button
                    className="px-4 py-1 loginbtn border btn-block mt-4 rounded"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? 'Verifying...' : 'Verify'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
