import { put } from "redux-saga/effects";
import { success, failed } from '../../slice/appointmentSlice'
import { postRequest } from "../ApiInstence";

export function* postAppointmentAPI({ payload }) {
    try {
        let header = {
            'Content-Type': 'multipart/form-data'
          }
        const response = yield postRequest(`appointments`, payload, header);
        if (response?.status === 200 || response?.status === 201) {
            yield put(success(response?.data));
        } else {
            yield put(failed(response));
        }
    } catch (error) {
        yield put(failed(error));
    }
}