import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { getoneuser } from '../../slice/userSlice';

const Myprofile = () => {
  const user = useSelector(store => store.user)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(user?.response);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('llllll', formData)
    alert('Profile updated successfully');
    setIsEditing(false);
  };

  useEffect(() => {
    dispatch(getoneuser({ email : user?.response?.id}))
    setFormData(user?.response)
  },[dispatch, formData])

  return (
    <>
      <div className=" myprofilebg d-flex align-items-center justify-content-center">
        <div className="container my-5 ">   
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8 col-md-8">
              <div className="bg-light p-5 rounded">
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="Full Name"
                          name="username"
                          value={user?.response?.name}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group mb-3">
                        <input
                          type="email"
                          className="form-control p-2 registerinputs"
                          placeholder="Email"
                          name="email"
                          value={user?.response?.email}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className="form-group mb-3">
                        <input
                          type="tel"
                          className="form-control p-2 registerinputs"
                          placeholder="Mobile Number"
                          name="mobile"
                          value={user?.response?.mobile}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-md-12 colinmobile'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="House No/Landmark"
                          name="address"
                          defaultValue={user?.response?.address}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="City"
                          name="city"
                          value={user?.response?.city}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="State"
                          name="state"
                          value={user?.response?.state}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="Zip / Postal Code"
                          name="zip"
                          value={user?.response?.zip_code}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="px-4 py-1 loginbtn border btn-block mt-4 rounded"
                      type="button"
                      onClick={handleEditToggle}
                    >
                      {isEditing ? 'Save' : 'Edit'}
                    </button>
                    {isEditing && (
                      <button
                        className="px-4 py-1 loginbtn border btn-block mt-4 rounded"
                        type="submit"
                      >
                        Save Changes
                      </button>
                    )}
                   
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Myprofile;
