import React from 'react'
import heroimage from '../Assets/images/teambanner.webp'
const Teambanner = () => {
  return (
    <div>
          <div className="border-0">
                <img className="card-img img-fluid" src={heroimage} alt="Cardimage" />
            </div>
    </div>
  )
}

export default Teambanner
