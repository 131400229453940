import React from 'react';
import p1 from '../Assets/images/calendar.webp';
import p3 from '../Assets/images/PerformTreatment.webp';
import p2 from '../Assets/images/medical-check.webp';
import p4 from '../Assets/images/precription.webp';
const HowWeWork = () => {
    return (
        <div>
            <div className='my-5 py-5 bghowwework'>
                <div className='container my-5'>
                    <div className='row p-0'>
                        <div className='col-md-6' data-aos="fade-left" data-aos-delay="100">
                            <h3 className='aboutheading pl-lg-1'>How We Work</h3>
                            <h5 className='aboutheading1'>
                                A Complete Resource
                                For <br /> All Your Health Care Needs
                            </h5>
                        </div>
                        <div className='col-md-6' data-aos="fade-right" data-aos-delay="200">
                            <p className='text-justify  pathakjiintro pt-lg-4 fs-5'>
                                At our center, we combine ancient wisdom with modern practices to deliver personalized and effective therapies. Our skilled practitioners carefully assess your needs and tailor each treatment to enhance your well-being. From revitalizing massages to targeted detox therapies, our approach ensures comprehensive care for a balanced and healthier you.
                            </p>
                        </div>
                    </div>
                    <div className=' row py-lg-5 px-0'>
                        <div className='col-md-3 pt-4 text-center' data-aos="fade-up" data-aos-delay="100">
                            <img src={p1} alt='Book An Appointment' className='img-fluid howweworkimg' width={150} />
                            <h3 className='hwwheading pt-3'>Book An Appointment</h3>
                        </div>
                        <div className='col-md-3 pt-3 text-center' data-aos="fade-up" data-aos-delay="200">
                            <img src={p2} alt='Conduct Checkup' className='img-fluid howweworkimg' width={150} />
                            <h3 className='pt-4 hwwheading'>Conduct Checkup</h3>
                        </div>
                        <div className='col-md-3 pt-1 text-center' data-aos="fade-up" data-aos-delay="300">
                            <img src={p3} alt='Perform Treatment' className='img-fluid howweworkimg' id="howweworkimgtreatment" width={300} />
                            <h3 className='pt-3 hwwheading'>Perform Treatment</h3>
                        </div>
                        <div className='col-md-3 pt-3 text-center' data-aos="fade-up" data-aos-delay="400">
                            <img src={p4} alt='Prescribe & Payment' className='img-fluid howweworkimg' width={150} />
                            <h3 className='pt-4 hwwheading'>Prescribe & Payment</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowWeWork;