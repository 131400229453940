// import Layout from 'common/Layout';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRouter = () => {
  const isUser = useSelector((state) => state.user.response);




  console.log(isUser)

  const isLoggedIn = isUser !== null && Object.keys(isUser).length && localStorage.getItem('token');
  return (
    <div>
      {isLoggedIn ? (
        <>
        
          <Outlet />
        </>
      ) : (
        <Navigate to={'/login'} replace/>
      )}
    </div>
  );
};
export default PrivateRouter;
