import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postOrder } from '../../slice/paymentSlice';
import crypto from 'crypto-js';
import { addAppointment, failed } from '../../slice/appointmentSlice';
import { useNavigate } from 'react-router-dom';
import CommonAlert from '../../common/CommonAlert';
import { postRequest } from '../../apis/ApiInstence';

const loadScript = (src) => new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
});

const RenderRazorpay = ({
    orderId,
    keyId,
    keySecret,
    currency,
    amount,
    name,
    setPaymentOption
}) => {
    const paymentId = useRef(null);
    const rzp1Ref = useRef(null);  // Store the Razorpay instance in a ref
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const order_data = useSelector(store => store.payment);
    const appointment_data = useSelector(store => store.appointment);
    const [appointmentData, setAppointmentData] = useState([]);

    const displayRazorpay = async (options) => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            console.log('Failed to load Razorpay SDK');
            return;
        }

        const rzp1 = new window.Razorpay(options);
        rzp1Ref.current = rzp1;

        // Open the Razorpay modal
        rzp1.open();
    };

    const displayRazorpay1 = async (options) => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            console.log('Failed to load Razorpay SDK');
            return;
        }

        const rzp1 = new window.Razorpay(options);
        rzp1Ref.current = rzp1;

        // Open the Razorpay modal
        rzp1.close();
    };

    const handlePayment = async (status, orderDetails = {}) => {
        if (status === 'succeeded') {
            dispatch(postOrder({
                payment_id: orderDetails?.paymentId?.current,
                user_id: name?.patient_mobile,
                status,
                details: orderDetails
            }));
        //    const order =  postRequest(`order`, {
        //         payment_id: orderDetails?.paymentId?.current,
        //         user_id: name?.patient_mobile,
        //         status,
        //         details: orderDetails
        //     });

        //     console.log(order)
            if (order_data?.response?.length) {
                name['payment_id'] = orderDetails?.paymentId?.current;
                // dispatch(addAppointment(name));
                let header = {
                    'Content-Type': 'multipart/form-data'
                  }
                const response = await postRequest(`appointments`, name, header);
                if(response?.data){
                    window.location.href = `/receipt/${response?.data?.id}`
                }
                setAppointmentData([response?.data])
                displayRazorpay1(options)
                if (rzp1Ref.current) {
                    rzp1Ref.current.close();
                }
                // setPaymentOption(false);
            }
        } else {
            if (rzp1Ref.current) {
                rzp1Ref.current.close();
            }
            setPaymentOption(false);
        }
    };

    const options = {
        key: keyId,
        amount,
        currency,
        name: name,
        order_id: orderId,
        handler: (response) => {
            paymentId.current = response.razorpay_payment_id;

            const succeeded = crypto.HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, keySecret).toString() === response.razorpay_signature;

            if (succeeded) {
                handlePayment('succeeded', {
                    orderId,
                    paymentId,
                    signature: response.razorpay_signature,
                });

                // Close the Razorpay modal on success
                if (rzp1Ref.current) {
                    rzp1Ref.current.close();  // Closing Razorpay modal here
                }
            } else {
                handlePayment('failed', {
                    orderId,
                    paymentId: response.razorpay_payment_id,
                });

                // Close the Razorpay modal on failure
                if (rzp1Ref.current) {
                    rzp1Ref.current.close();
                }
            }
        },
        modal: {
            confirm_close: true,
            ondismiss: () => {
                // Handle when the user dismisses the Razorpay modal without completing payment
                handlePayment('Cancelled');
                setPaymentOption(false);  // Close payment option if Razorpay modal is dismissed

                // Close Razorpay modal when dismissed manually
                if (rzp1Ref.current) {
                    rzp1Ref.current.close();
                }
            },
        },
        retry: {
            enabled: false,
        },
        theme: {
            color: '',  // Custom color for the modal
        },
    };

    useEffect(() => {
        displayRazorpay(options);
    }, []);

   

    const onConfirm = () => {
        navigate(`/receipt/${appointmentData?.id}`);
        dispatch(failed());
    };

    return (
        <>
              {appointmentData?.length && (
                <CommonAlert
                    title="Appointment Booked Successfully"
                    text="We will ocntact you soon"
                    icon="success"
                    confirmButtonText="Ok"
                    onConfirm={onConfirm}
                />
            )}
        </>
    );
};

export default RenderRazorpay;
