import { PayloadAction } from "@reduxjs/toolkit";
import { put, take, takeLatest } from "redux-saga/effects";
import { signup, signin, success, failed, otpverification, successLogin, getoneuser } from '../slice/userSlice'
import { getRequest, postRequest } from "./ApiInstence";
import { toast } from "react-toastify";
import {getProductAPI, getOneProductAPI} from "./product/productApi";
import { getProduct, getOneProduct } from "../slice/productSlice";
import { addContectUs } from "../slice/contactusSlice";
import { addAppointment } from "../slice/appointmentSlice";
import { postContactUsAPI } from "./contactus/contactUsApi";
import { postAppointmentAPI } from "./appointment/appointmentApi";
import { getorderId, postOrder } from "../slice/paymentSlice";
import { getOrderIDAPI, postMakePaymentAPI } from "./payment/paymentApi";
import { postOrderDeatils, getAllOrderDetailsAPI, getOrderDetailAPI } from "./order/orderApi";
import { placeorder, getAllOrderDetails, getOrderDetail } from "../slice/orderSlice";

function* signinUser({ payload }) {
  try {
    const response = yield postRequest('customer/login', payload);
    if (response?.status == 200) {
      yield put(successLogin(response));
    } else {
      yield put(failed(response));
    }

  } catch (error) {
    yield put(failed(error));
  }
}

function* getoneuserFun({ payload }) {
  try {
    const response = yield getRequest('customer/one/' + payload?.email);
    if (response?.status === 200) {
      yield put(success(response));
    } else {
      yield put(failed(response));
    }

  } catch (error) {
    yield put(failed(error));
  }
}

function* otpverification_fun({ payload }) {
  try {
    const response = yield postRequest('customer/otp', payload);
    if (response?.status === 200) {
      yield put(success(response));
    } else {
      yield put(failed(response));
    }

  } catch (error) {
    yield put(failed(error));
  }
}

function* signupUser({ payload }) {

  try {

    const response = yield postRequest('customer', payload);
    if (response?.status === 200) {
      yield put(success(response));
    } else {
      yield put(failed(response));
    }

  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }

}

// Generator function
export function* watchGetUser() {
  yield takeLatest(signin, signinUser);
  yield takeLatest(signup, signupUser);
  yield takeLatest(otpverification, otpverification_fun);
  yield takeLatest(getoneuser, getoneuserFun);

  yield takeLatest(getProduct, getProductAPI)
  yield takeLatest(getOneProduct, getOneProductAPI)

  yield takeLatest(addContectUs, postContactUsAPI)
  yield takeLatest(addAppointment, postAppointmentAPI)
  yield takeLatest(placeorder, postOrderDeatils)
  yield takeLatest(getAllOrderDetails, getAllOrderDetailsAPI)
  yield takeLatest(getOrderDetail, getOrderDetailAPI)



  //payment APIS
  yield takeLatest(getorderId, getOrderIDAPI)
  yield takeLatest(postOrder, postMakePaymentAPI)

}