import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";
import { useSelector, useDispatch } from 'react-redux';
import { removeProduct } from '../../slice/cartSlice';
import { failed } from '../../slice/orderSlice';
const Cart = () => {
  const cartItems = useSelector((store) => store.cart);

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const checkout = () =>{
    dispatch(failed())
    navigate("/checkout")
  }
  const calculateTotal = (items) => {
    return items.reduce((total, item) => total + item.sprice * item.qty, 0).toFixed(2);
  };
  return (
    <div className="container my-5">
      <h2 className="pb-lg-5 text-center aboutheading">My Cart</h2>
      <div className='table-responsive leftspace'>
      <table className="table border text-center">
        <thead>
          <tr className='custom-cart-header'>
            <th>Product</th>
            <th>Name</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Delete</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item, index) => (
            <tr key={index}>
              <td><img className='cartpimg' src={`${process.env.REACT_APP_BACKEND_URL_REL}${item.image}`} alt={item.name} /></td>
              <td>{item.name}</td>
              <td>{item.sprice.toFixed(2)}</td>
              <td>{item.qty}</td>
              <td>
                <button className='btn btn-danger btn-sm' onClick={() => dispatch(removeProduct(item))}>
                  <RxCross2 />
                </button>
              </td>
              <td>{(item.sprice * item.qty).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5" className="text-center font-weight-bold">Subtotal</td>
            <td className="font-weight-bold">
            Rs {calculateTotal(cartItems)} 
            </td>
          </tr>
          <tr>
            <td colSpan="5" className="text-center font-weight-bold">Total</td>
            <td className="font-weight-bold">
            Rs {calculateTotal(cartItems)}
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="my-5 text-center pb-5">
        <button onClick={() => checkout()} className="checkout-btn text-decoration-none border-0">Checkout</button>
      </div>
    </div>
    </div>
  );
};

export default Cart;
