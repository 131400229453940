import React from 'react'
import drpathaks from '../../Assets/images/drpathakji.webp'
import drruchi from '../../Assets/images/ruchiguptamam.webp'
import drrina from '../../Assets/images/drmam.webp'
import {Link} from 'react-router-dom'

const Doctors = () => {
    return (
        <div>
            <div className='container my-5 py-5'>
                <div className="intro" data-aos="fade-up">
                    <h2 className="text-center aboutheading" >Our Specialists</h2>
                    <h6 className='text-center pt-2 pb-5'> Meet our experienced specialists who are here to assist you with your needs.</h6>
                </div>
                <div className="row people leftspace py-lf-5">
                    <div className="col-md-6 col-lg-4 item" data-aos="fade-up" data-aos-delay="100">
                        <div className="box">
                            <img className="rounded-circle" src={drpathaks} alt='Dr Raghav Pathak' />
                            <h4 className="name">Dr Raghav Pathak</h4>
                            <p className="title">BHMS DNYS</p>
                            <p className="description">
                            Senior Homoeopathy and Naturopathy Physician 
                            </p>
                            {/* Reg.No. 16813 */}
                            <Link to='/ourteam'>
                                <button className="text-decoration-none pathakjibtn font-weight-bold">
                                More Info
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 item" data-aos="fade-up" data-aos-delay="200">
                        <div className="box">
                            <img className="rounded-circle" src={drrina} alt='Dr Ruchi Pathak' />
                            <h4 className="name">Dr.Reena Mudiya </h4>
                            <p className="title">  BNYS </p>
                            <p className="description">
                            
                            Naturopathy  and yoga physician <br />
                            </p>
                            {/* Reg. No. 57745 */}
                            <Link to='/'>
                                <button className="text-decoration-none pathakjibtn font-weight-bold">
                                    More Info
                                </button>
                            </Link>                      
                          </div>
                    </div>
                    <div className="col-md-6 col-lg-4 item" data-aos="fade-up" data-aos-delay="300">
                        <div className="box">
                            <img className="rounded-circle" src={drruchi} alt='Dr Anshul Pathak' />
                            <h4 className="name">Dr Ruchi Gupta</h4>
                            <p className="title">BHMS CGO </p>
                            <p className="description">
                            Gyanecology and skin care
                            </p>
                            <Link to='/'>
                                <button className="text-decoration-none pathakjibtn font-weight-bold">
                                    More Info
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Doctors