import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  response: null,
  loading: false,
  error: null,
};

export const appointment = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    addAppointment: (state, payload) => {
      state.loading = true;
    },
    success: (state, {payload})=>{
        state.loading = false;
        state.response = payload
      },
      failed:(state)=>{
        state.loading = false;
        state.response = null;
      },
      getAppointmentDetail:(state)=>{
        state.loading = true; 
      }
  },
})

// Action creators are generated for each case reducer function
export const { success, failed, addAppointment } = appointment.actions

export default appointment.reducer