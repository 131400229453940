import { put } from "redux-saga/effects";
import { success, failed } from '../../slice/orderSlice'
import { getRequest, postRequest } from "../ApiInstence";

export function* postOrderDeatils({ payload }) {
    try {
        const response = yield postRequest(`order`, payload);
        if (response?.status === 200 || response?.status === 201) {
            yield put(success([response?.data]));
        } else {
            yield put(failed(response));
        }
    } catch (error) {
        yield put(failed(error));
    }
}

export function* getAllOrderDetailsAPI({ payload }) {
    try {
        const response = yield getRequest(`order/${payload}`);
        if (response?.status === 200 || response?.status === 201) {
            yield put(success(response?.data));
        } else {
            yield put(failed(response));
        }
    } catch (error) {
        yield put(failed(error));
    }
}

export function* getOrderDetailAPI({ payload }) {
    try {
        const response = yield getRequest(`order/order_details/${payload}`);
        if (response?.status === 200 || response?.status === 201) {
            yield put(success(response?.data));
        } else {
            yield put(failed(response));
        }
    } catch (error) {
        yield put(failed(error));
    }
}