import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import RenderRazorpay from '../../components/payment/RenderRazorpay';
import { FaLock } from 'react-icons/fa';
import { generateOrderID } from '../../common/CommonFun';
import { failed, placeorder } from '../../slice/orderSlice';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Checkout = () => {
    const cartData = useSelector((store) => store.cart);
    const user = useSelector(store => store.user)
    const order = useSelector(store => store.order)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const order_data = useSelector(store => store.payment)
    const [paymentOption, setPaymentOption] = useState(false)
    const [formData, setFormData] = useState({
        country: '',
        full_name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        phone_number: '',
        email_address: '',
        paymentMethod: '',
    });

    const [showPopup, setShowPopup] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const calculateTotal = (items) => {
        return items.reduce((total, item) => total + item.sprice * item.qty, 0).toFixed(2);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            'paymentMethod': 'online'
        });
        // formData.paymentMethod = true
        // const orderDetails = {
        //     'orderID': generateOrderID(),
        //     'customerID': user?.response?.id,
        //     'totalAmount': calculateTotal(cartData),
        //     'shippingAddress': formData?.address,
        //     'paymentMethod': formData?.paymentMethod,
        //     'item_details': cartData
        // }
        // dispatch(placeorder(orderDetails))
    };

    useEffect(()=>{
        if(order?.response?.length){
            localStorage.removeItem('cartItems')
            dispatch(failed())
            MySwal.fire({
                title: 'Order Placed ',
                text: 'Please check the status',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Continue',
              });
            navigate('/myorder')
        }
    },[dispatch, order])



    return (
        <div className="container my-5 pb-5">
             {formData.paymentMethod === "online" && (
                                    <RenderRazorpay
                                        orderId={order_data?.response?.[0]?.order_id}
                                        keyId={'rzp_test_jL3UplbraK01a5'}
                                        keySecret={'0rbAAXWTiONv2UY37kiQgQmUdx'}
                                        currency={'INR'}
                                        amount={calculateTotal(cartData) * 100}
                                        setPaymentOption={setPaymentOption}
                                    />
                                )}
            <h3 className="text-center pb-5 aboutheading">Checkout</h3>
            <div className="row">
                <div className="col-lg-6">
                    <form onSubmit={handleSubmit}>
                        <div className="card mb-4">
                            <div className="card-header custom-cart-header">
                                <h5>Shipping Address</h5>
                            </div>
                            <div className="card-body">                          
                                <div className="form-group">
                                    <label htmlFor="address">Full Address</label>
                                    <textarea
                                        type="textarea"
                                        name="address"
                                        className="form-control cehckoutinput"
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="phone_number">Phone Number</label>
                                            <input
                                                type="text"
                                                name="phone_number"
                                                className="form-control cehckoutinput"
                                                value={formData.phone_number}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div className="card mb-4 d-none">
                            <div className="card-header  custom-cart-header">
                                <span><FaLock /></span> Secure Payment
                            </div>
                            <div className="card-body">
                                {/* Payment Form */}
                                <div className="form-group">
                                    <label>Payment Method:</label>
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id="onlinePayment"
                                            name="paymentMethod"
                                            value="online"
                                            checked={formData.paymentMethod === "online"}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="onlinePayment">Online Payment</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id="cashOnDelivery"
                                            name="paymentMethod"
                                            value="cashOnDelivery"
                                            checked={formData.paymentMethod === "cashOnDelivery"}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="cashOnDelivery">Cash on Delivery</label>
                                    </div>
                                </div>

                                {formData.paymentMethod === "online" && (
                                    <RenderRazorpay
                                        orderId={order_data?.response?.[0]?.order_id}
                                        keyId={'rzp_test_jL3UplbraK01a5'}
                                        keySecret={'0rbAAXWTiONv2UY37kiQgQmUdx'}
                                        currency={'INR'}
                                        amount={calculateTotal(cartData) * 100}
                                        setPaymentOption={setPaymentOption}
                                    />
                                )}

                                {formData.paymentMethod === "cashOnDelivery" && (
                                    <div className="form-group">
                                        <p>You have selected Cash on Delivery. You will pay in cash when your order is delivered.</p>
                                    </div>
                                )}

                                <div className="form-group">
                                    <button type="submit" className="py-1 loginbtn btn-block">Place Order</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                                    <button type="submit" className="py-1 loginbtn btn-block">Place Order</button>
                                </div>
                    </form>
                </div>

                {/* Order Review Section */}
                <div className="col-lg-6">
                    <div className="card mb-4">
                        <div className="card-header custom-cart-header">
                            <h5>Review Order <span><Link to="/cart" className="float-right text-white text-decoration-none">Edit Cart</Link></span></h5>
                        </div>
                        <div className="card-body">
                            {cartData.map((item, index) => (
                                <div key={index} className="d-flex mb-3">
                                    <div className="mr-3">
                                        <img className="img-fluid" width={50} src={`${process.env.REACT_APP_BACKEND_URL_REL}${item.image}`} alt={item.name} />
                                    </div>
                                    <div className="flex-grow-1">
                                        <div>{item.name}</div>
                                        <small>Quantity: {item.qty}</small>
                                    </div>
                                    <div className="text-right">
                                        <h6>Rs {item.sprice * item.qty}.00</h6>
                                    </div>
                                </div>
                            ))}
                            <div className="border-top pt-3">
                                <div className="d-flex justify-content-between">
                                    <strong>Sub Total</strong>
                                    <span>Rs {calculateTotal(cartData)} </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <strong>Total</strong>
                                    <span>Rs {calculateTotal(cartData)} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Popup Modal */}
            {showPopup && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Thank You!</h5>
                                <button type="button" className="close" onClick={() => setShowPopup(false)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Your order has been placed successfully.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowPopup(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" onClick={() => setShowPopup(false)}></div>
                </div>
            )}
        </div>
    );
};

export default Checkout;
