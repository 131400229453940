import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Feedbacknew = () => {
  return (
    <div className="container my-5 py-lg-5">
         <div className="section-title">
      <div className="container section-title text-center" data-aos="fade-up">
            <h2 className='text-center aboutheading'>Happy Clients</h2>
            <h6 className='text-center pt-2 pb-lg-5'>Our Clients' Experiences and Success Stories</h6>
           
           </div>
      </div>
      <div></div>
      <div id="feedbackCarousel" className="carousel slide pt-lg-5" data-bs-ride="carousel">
        <div className="carousel-inner">
          {/* First Slide */}
          <div className="carousel-item active">
            <div className="row leftspace">
              <div className="col-md-4 colstyle">
                <div className="card topcard d-flex mx-auto">
                  <div className="card-text ourcardtext">
                    <div className='text-center pt-lg-2'>
                    <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback " />
                    </div>
                    <div className='feedtext pt-lg-3'>
                    <p className="servicetext">     It's been 3 months since I started eating your food, and I feel greatly relieved now. Thank you, sir! </p>
                    </div>
                  </div>
                  <div className="ourcardfooter">
                    <h3  className='serviceheadingfeed'>Aneeta Ravindra Ahirwar<br /></h3>
                    <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 colstyle">
                <div className="card topcard d-flex mx-auto">
                  <div className="card-text ourcardtext">
                    <div className='text-center pt-lg-2'>
                    <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback " />
                    </div>
                    <div className='feedtext pt-lg-3'>
                    <p className="servicetext">Great treatment with homeopathy and ayurvedic methods. I experienced significant relief from your care, sir 😊🙏</p> 
                  </div>
                  </div>
                  <div className="ourcardfooter">
                  <h3  className='serviceheadingfeed'>Faimeeda Khatoon<br /></h3>
                    <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 colstyle">
                <div className="card topcard d-flex mx-auto">
                  <div className="card-text ourcardtext">
                    <div className='text-center pt-lg-2'>
                    <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback " />
                    </div>
                    <div className='feedtext pt-3'>
                    <p className="servicetext">I am 100% satisfied with your treatment. I was suffering a lot due to sciatica, and now I feel much better. Thank you, sir 🙏🏻🙏🏻😊😊.</p> 
                 </div>
                  </div>
                  <div className="ourcardfooter">
                  <h3  className='serviceheadingfeed'>Shivam “SHIV”<br /></h3>
                    <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second Slide */}
          <div className="carousel-item">
            <div className="row leftspace">
              <div className="col-md-4 colstyle">
                <div className="card topcard d-flex mx-auto">
                  <div className="card-text ourcardtext">
                    <div className='text-center pt-lg-2'>
                    <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback " />
                    </div>
                    <div className='feedtext pt-lg-3'>
                    <p className="servicetext">  Dr. Pathak's treatment for sciatica pain has been incredibly effective.</p>
                 </div>
                  </div>
                  <div className="ourcardfooter">
                    <h3  className='serviceheadingfeed'>माधव शरण पाठक<br /></h3>
                    <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 colstyle">
                <div className="card topcard d-flex mx-auto">
                  <div className="card-text ourcardtext">
                    <div className='text-center pt-lg-2'>
                    <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback " />
                    </div>
                    <div className='feedtext pt-lg-3'>
                    <p className="servicetext"> Dr. Pathak provides excellent treatment with a holistic approach, ensuring 100% satisfaction.</p>
                 </div>
                  </div>
                  <div className="ourcardfooter">
                  <h3  className='serviceheadingfeed'>Pragati Patairiya<br /></h3>
                    <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 colstyle">
                <div className="card topcard d-flex mx-auto">
                  <div className="card-text ourcardtext">
                    <div className='text-center pt-lg-2'>
                    <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback " />
                    </div>
                    <div className='feedtext pt-lg-3'> 
                    <p className="servicetext">  Had a great experience with Dr. Pathak's treatment and achieved 100% relief!</p>
                  </div>
                  </div>
                  <div className="ourcardfooter">
                  <h3  className='serviceheadingfeed'>DHAIRYA VLOGGER<br /></h3>
                    <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button className="carousel-control-prev" type="button" data-bs-target="#feedbackCarousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#feedbackCarousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Feedbacknew;
